import TitleCtaType from "components/Layout/types/TitleCtaType";
import useNavTabs from "features/project/hooks/useNavTabs";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle, setSecondaryNavItems, setTitleCtas, setTooltip } from "store/layoutSlice";

export interface IUseLayoutConfig {
    canExportScores?: boolean;
    canModerateScores: boolean;
    canAskQuestion: boolean;
    projectInformation: IProjectInformation;
    handleDownload: () => void;
    setIsProjectPreviewOpen: (isProjectPreviewOpen: boolean) => void;
    backTo?: string;
}

export const useLayout = (props: IUseLayoutConfig) => {
    const {
        canExportScores,
        canModerateScores,
        projectInformation,
        handleDownload,
        canAskQuestion,
        setIsProjectPreviewOpen,
        backTo,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { buyerTabItems } = useNavTabs();
    const setLayout = () => {
        dispatch(
            setPageTitle(canModerateScores ? t("tenderCriteria.moderateScore.title") : t("tenderCriteria.score.title")),
        );
        dispatch(
            setTooltip(
                canModerateScores
                    ? t("tenderCriteria.moderateScore.tooltip")
                    : [
                          { text: t("tenderCriteria.score.tooltip.paragraph1") },
                          { text: t("tenderCriteria.score.tooltip.paragraph2") },
                          { text: t("tenderCriteria.score.tooltip.paragraph3") },
                          { text: t("tenderCriteria.score.tooltip.paragraph4") },
                          { text: t("tenderCriteria.score.tooltip.paragraph5") },
                      ],
            ),
        );
        dispatch(
            setBack(
                backTo === "tenderSummary"
                    ? { route: buildRoute(routes.projects.tenderSummary, { projectUuid: projectInformation.uuid }) }
                    : { route: buildRoute(routes.projects.monitor, { projectUuid: projectInformation.uuid }) },
            ),
        );
        dispatch(setSecondaryNavItems(buyerTabItems));

        const titleCtas = [
            {
                type: TitleCtaType.ProjectPreview,
                onClick: () => setIsProjectPreviewOpen(true),
            },
        ];

        if (canAskQuestion) {
            titleCtas.push({
                type: TitleCtaType.AskQuestion,
                onClick: () =>
                    buildPath(routes.projects.askClarificationQuestion, { projectUuid: projectInformation.uuid }),
            });
        }

        if (canExportScores) {
            titleCtas.push({
                type: TitleCtaType.Export,
                onClick: handleDownload,
            });
        }

        dispatch(setTitleCtas(titleCtas));
    };
    return { setLayout };
};
